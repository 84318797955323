import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

function isDark(p) {
  return p.theme.colors.articleText === p.theme.colors.modes.dark.articleText;
}

const Blockquote = styled.blockquote<{ p: any }>`
  transition: ${p => p.theme.colorModeTransition};
  margin: 10px auto 36px;
  font-family: ${p => p.theme.fonts.serif};
  border-left: 6px solid ${p => isDark(p) ? "#BBDEFB" : "#009688"};
  padding: 10px 5px 10px 18px;
  background: ${p => isDark(p) ? `#455a64` : `#e5f3f3e6`};
  width: 80%;
  border-radius: 3px;
  max-width: 720px;

  ${mediaqueries.tablet`
    margin: 10px auto 35px;
  `};

  & > p {
    max-width: 720px !important;
    padding-right: 20px;
    padding-bottom: 0;
    width: 100%;
    margin: 0 auto;
    font-size: 0.9em;
    line-height: 1.32;
    font-family: Verdana, sans-serif;
    color: ${p => isDark(p) ? "#fff" : "#000"};

    a {
      color: ${p => isDark(p) ? "#B74CF5" : "#6166DC"} !important;
      text-decoration: underline !important;
    }
  }
`;

export default Blockquote;
