import styled from "@emotion/styled";
import { css } from "@emotion/react"; // Import the css function
import mediaqueries from "@styles/media";

const Paragraph = styled.p`
  line-height: 1.756;
  font-size: 18px;
  color: ${p => p.theme.colors.articleText};
  font-family: 'Manrope', sans-serif;
  transition: ${p => p.theme.colorModeTransition};
  margin: 0 auto 35px;
  width: 100%;
  max-width: 680px;

  b {
    font-weight: 800;
  }

  ${mediaqueries.desktop`
    max-width: 680px;
  `}

  ${mediaqueries.tablet`
    max-width: 486px;
    margin: 0 auto 25px;
  `};

  ${mediaqueries.phablet`
    padding: 0 20px;
  `};

  /* Responsive styles for embedVideo-container */
  .embedVideo-container {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    border-radius: 8px;
  }

  .embedVideo-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default Paragraph;
